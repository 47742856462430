import React from 'react'

import SEO from '../components/seo'

const NotFoundPage = () => (
  <>
    <SEO title="404: Not found" />
    <div
      className="w-100 absolute bottom-0 flex items-center justify-center"
      style={{ top: '60px' }}
    >
      <div className="ph3 nt6">
        <h1 className="f-subheadline f-headline-ns mv0">404</h1>
        <h2 className="f4 f3-ns mt0 mb3 mb4-ns lh-title">
          Oops, a página que você está procurando não existe.
        </h2>
        <p className="lh-copy f6 f4-ns mb3 mb4-ns">
          Você pode &nbsp;
          <a href="/" className="black">
            voltar à página inicial
          </a>
          &nbsp;do Immortalis.
          <br />
          Se você acha que encontrou algum problema no site,&nbsp;
          <a href="/#contact" className="black">
            entre em contato conosco
          </a>
          .
        </p>
        <a
          href="/create-memorial"
          className="btn btn-primary btn-primary-outline dib mr2 mb3 w-100 w-auto-ns black b--black hover-bg-black hover-white"
        >
          Criar Memorial
        </a>
        <a
          href="/create-tribute"
          className="btn btn-primary btn-primary-outline dib mr2 mb2 w-100 w-auto-ns black b--black hover-bg-black hover-white"
        >
          Criar Tributo
        </a>
      </div>
    </div>
  </>
)

export default NotFoundPage
